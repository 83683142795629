import React, { useState } from "react";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MNavbar.scss";
import { COLORS } from "../../../../SupportingFiles/colors";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MLoginPopup from "./MLoginPopup/MLoginPopup";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";

function MNavBar() {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openLoginPopup, setOpenLoginPopup] = React.useState(false);

  const toggleDrawer =
    (anchor: "left", open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenMenu(open);
    };

  return (
    <Box className="mnavbar">
      <MLoginPopup open={openLoginPopup} setOpen={setOpenLoginPopup} />
      <Grid container className="row" columnSpacing={3} alignItems={"center"}>
        <Grid xs>
          <Link className="hamburger-menu" onClick={toggleDrawer("left", true)}>
            <span></span>
          </Link>
        </Grid>
        <Grid xs textAlign="center">
          <img
            src="./images/motilalOswal-logo.svg"
            height="36px"
            alt="Motilal Oswal Insurance"
            onClick={() => navigate(COMMON_ROUTES.HOME)}
            style={{ cursor: "pointer" }}
          />
        </Grid>
        {/* <Grid xs textAlign={"right"}>
          <Link className="login-link" onClick={() => setOpenLoginPopup(true)}>
            <LoginRoundedIcon />
            Login
          </Link>
        </Grid> */}
      </Grid>

      {/* sidemenu */}
      <SwipeableDrawer
        anchor="left"
        open={openMenu}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        <Box className="menuWrapper">
          <Box className="topbar">
            <Link
              className="hamburger-menu active"
              onClick={toggleDrawer("left", false)}
            >
              <span></span>
            </Link>
          </Box>
          <Box className="menu-section">
            <ul>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.CONTACT_US)}
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.CLAIMS)}
                >
                  Claims
                </Link>
              </li>
            </ul>
          </Box>
          <Box className="menu-section">
            <p className="menu-heading">Products</p>
            <ul>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}
                >
                  Health Insurance
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}
                >
                  Term Insurance
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}
                >
                  Car Insurance
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link"
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}
                >
                  Two Wheeler insurance
                </Link>
              </li>
            </ul>
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

export default MNavBar;
