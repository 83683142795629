import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import FAQ from "../../../Component/FAQ/FAQ";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import TWProductContainer from "../../../Container/TWProductContainer/TWProductContainer";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

function TWProductPage() {
  const isMobile = useIsMobile();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts or updates
    window.scrollTo(0, 0);
  }, []);

  const defaultShow = 10;
  const [showAllFaqs, setShowAllFaqs] = useState(false);

  const faqs = [
    {
      title: `What is Two-Wheeler insurance?`,
      desc: `Two-Wheeler insurance is a type of policy that provides financial protection against damages to your Two-Wheeler, third-party liabilities, theft, and personal accidents.`,
    },
    {
      title: "Is Two-Wheeler insurance mandatory in India?",
      desc: `Yes, as per the Motor Vehicles Act, having at least third-party liability insurance is mandatory for all two-wheeler owners in India.`,
    },
    {
      title:
        "What is the difference between third-party and comprehensive bike insurance? ",
      desc: (
        <>
          <ul>
            <li>
              <strong>Third-Party Insurance</strong>: Covers damages and
              injuries caused to a third party.
            </li>
            <li>
              <strong>Comprehensive Insurance</strong>: Includes third-party
              coverage as well as protection against own damages, theft, fire,
              and natural calamities.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "What is Zero Depreciation cover in Two-Wheeler insurance?",
      desc: `Zero Depreciation cover ensures that you receive the full claim amount without any deductions for the depreciation of bike parts.`,
    },
    {
      title:
        "Can I transfer my Two-Wheeler insurance to a new owner if I sell my Two-Wheeler?",
      desc: `Yes, you can transfer your Two-Wheeler insurance policy to the new owner. Inform your insurer about the sale, and they will guide you through the transfer process. The new owner will need to provide relevant documents and information for the transfer.`,
    },
    {
      title: "How can I renew my Two-Wheeler insurance policy?",
      desc: "You can easily renew your Two-Wheeler insurance policy online through our website or mobile app. Simply enter your policy details and make the payment. Renewing your policy on time ensures continuous coverage.",
    },
    {
      title:
        "What documents are required to file a Two-Wheeler insurance claim?",
      desc: (
        <>
          Typically, you need the following documents:
          <br />
          <ul>
            <li>• Copy of the insurance policy</li>
            <li>• FIR (in case of theft or major accidents)</li>
            <li>• Driving license</li>
            <li>• Registration certificate of the Two-Wheeler</li>
            <li>• Claim form duly filled and signed</li>
          </ul>
        </>
      ),
    },
    {
      title: "How is the premium for Two-Wheeler insurance calculated?",
      desc: `The premium is calculated based on factors such as the Two-Wheeler's make and model, age, location, type of coverage, and add-on covers.`,
    },
    {
      title:
        "What should I do if I lose my Two-Wheeler insurance policy document?",
      desc: `If you lose your Two-Wheeler insurance policy document, you can request a duplicate copy from your insurer by providing your policy details and identification proof.`,
    },
    {
      title: "What is No Claim Bonus (NCB) in Two-Wheeler insurance?",
      desc: `No Claim Bonus is a discount on the premium for not making any claims during the previous policy term. It accumulates with each claim-free year and can significantly reduce your premium.`,
    },
    {
      title:
        "Can I get Two-Wheeler insurance for an old or second-hand Two-Wheeler?",
      desc: `Yes, you can get insurance for an old or second-hand Two-Wheeler. The premium will be based on the Two-Wheeler's current market value, age, and condition.`,
    },
    {
      title: "What factors affect the Two-Wheeler insurance premium?",
      desc: `Several factors influence the Two-Wheeler insurance premium, including the Two-Wheeler's make and model, age, location, type of coverage, add-ons, and your claims history.`,
    },
    {
      title: "Is it possible to switch my Two-Wheeler insurance provider?",
      desc: `Yes, you can switch your Two-Wheeler insurance provider at the time of renewal. Compare different policies and choose the one that best suits your needs.`,
    },
    {
      title: "What is an IDV in Two-Wheeler insurance?",
      desc: `IDV (Insured Declared Value) is the current market value of your Two-Wheeler. It is the maximum sum assured by the insurer in case of total loss or theft of the bike.`,
    },
    {
      title:
        "Can I get Two-Wheeler insurance if I don't have a valid driving license?",
      desc: `Yes, you can buy an insurance policy of your vehicle. However, you cannot legally drive your vehicle on road. Only license holder can drive vehicle on road.`,
    },
    {
      title: "What happens if my Two-Wheeler insurance policy expires?",
      desc: `If your Two-Wheeler insurance policy expires, you won't be covered for any damages or liabilities. You should renew it promptly to avoid any gaps in coverage.`,
    },
    {
      title: "Can I add accessories to my Two-Wheeler insurance policy?",
      desc: `Yes, you can cover accessories like custom parts and modifications under your Two-Wheeler insurance policy. Inform your insurer and get an add-on cover for accessories.`,
    },
    {
      title: "How do I claim Two-Wheeler insurance for theft?",
      desc: `In case of theft, file an FIR at the nearest police station, inform your insurer, and submit the required documents, including the FIR copy, policy document, and claim form.`,
    },
    {
      title:
        "What is the role of an insurance surveyor in Two-Wheeler insurance?",
      desc: `An insurance surveyor assesses the damage to your Two-Wheeler and verifies the extent of the loss to determine the claim amount.`,
    },
    {
      title: "Are there any discounts available on Two-Wheeler insurance?",
      desc: `Yes, insurers offer discounts on Two-Wheeler insurance for installing anti-theft devices, being a member of a recognized automobile association, and maintaining a no-claim record.`,
    },
    {
      title:
        "What is the procedure for changing the Two-Wheeler insurance provider?",
      desc: `To change your Two-Wheeler insurance provider, compare different policies, choose a new insurer, and initiate the renewal process before your current policy expires.`,
    },
  ];

  return (
    <Box className="productWrapper res-wrapper ">
      {isMobile ? <MNavBar /> : <NavBar />}

      {/* responsive sec container for responsive Pages */}
      <Box className="responsive-sec mt-6" marginBottom={"50px"}>
        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <Box className="formBox">
              <TWProductContainer />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row"
          marginBottom={"30px"}
          alignItems={"center"}
        >
          <Grid xs={12} md={9}>
            <h3 className="mb-2">
              Gear up for a smooth ride with Two-Wheeler insurance.
            </h3>
            <p className="mb-4">
              Two-wheelers offer a convenient and economical mode of
              transportation. However, they are also vulnerable to accidents,
              theft, and other unforeseen events. As a responsible rider, having
              a comprehensive bike insurance policy ensures financial security
              and peace of mind. Here are some of the reasons why you should
              have an insurance policy for two-wheeler: -
            </p>
            <ol className="productpage_OLpoints">
              <li>
                It provides financial coverage in case your two-wheeler is lost,
                stolen, or suffers any damage, you will get financial protection
                for all such unforeseen events.
              </li>
              <li>
                According to the Motor Vehicles Act, 1988, having at least
                third-party liability insurance is mandatory for all two-wheeler
                owners in India. If found riding without a valid insurance
                policy, you would end up paying hefty fines.
              </li>
              <li>
                There are multiple add-ons that you can include in your
                two-wheeler insurance policy at a higher premium but would offer
                greater protection.
              </li>
              <li>
                A Personal Accident Cover is also mandatory for all Two-Wheeler
                owners.
              </li>
              <li>
                It provides financial coverage for damage to all two-wheelers,
                including mopeds, Two-Wheelers, scooters etc.
              </li>
            </ol>
          </Grid>
          <Grid xs={12} md={3} textAlign={"center"}>
            <img
              src="./images/bike-product.svg"
              alt=""
              style={{ maxWidth: "270px", width: "100%" }}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-2">Types of Two-Wheeler insurance</h3>
            <ul className="pp_darkHeadlist">
              <li>
                <span>Comprehensive Cover -</span> comprehensive Insurance cover
                provides extensive coverage beyond basic liability and collision
                insurance, including protection against theft, vandalism,
                natural disasters, and damage not caused by a collision.
              </li>
              <li>
                <span>Standalone Own Damage Policy -</span> When only
                Third-Party policy in force, vehicle owner can buy a Standalone
                Own Damage policy to cover for damages caused to his vehicle as
                well.
              </li>
              <li>
                <span>Third-Party Liability (Mandatory) -</span> Third-party
                motor insurance is the most basic and legal form of motor
                insurance policy you will need. As the name suggests, this will
                only cover damage and injuries caused to a third-party and won't
                cover your own damages.
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-2">Get a Quote in 3 Easy Steps: </h3>
            <ol className="productpage_OLpoints">
              <li>Visit our portal and enter your Two-Wheeler details. </li>
              <li>Compare quotes from various motor insurance companies.</li>
              <li>
                Choose the plan that best suits your needs and complete the
                online purchase.
              </li>
            </ol>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-2">
              How to choose the right Two-Wheeler insurance online
            </h3>
            <p>
              Here are some useful tips to help you choose the right bike
              insurance policy based on your needs and budget:
            </p>
            <ol className="productpage_OLpoints mt-3">
              <li>
                <span>Assess Your Coverage Needs -</span> Before searching for a
                bike insurance plan, evaluate your requirements and budget. You
                can choose between third-party cover and comprehensive cover.
                Depending on how you use your two-wheeler, select a bike
                insurance plan that offers the coverage you need.
              </li>
              <li>
                <span>Insured Declared Value (IDV) -</span> IDV represents the
                current market value of your bike. It is the maximum sum insured
                set when purchasing a bike insurance policy and is the amount
                the insurer will pay in case of total loss or theft of the
                two-wheeler. Therefore, IDV is a crucial factor in determining
                the two-wheeler insurance premium.
              </li>
              <li>
                <span>
                  Consider Add-Ons to Enhance Your Bike Insurance Coverage -
                </span>{" "}
                Look for additional riders you can add to your bike insurance
                policy to extend the coverage. While you will need to pay an
                extra premium for these riders, they can provide more
                comprehensive protection.
              </li>
              <li>
                <span>Compare Bike Insurance Online -</span> It's wise to
                compare bike insurance plans online to make an informed
                decision. You can review different plans based on the coverage
                they offer, helping you choose the best option for your needs.
              </li>
            </ol>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-2">
              Two-Wheeler insurance add-ons which you can buy with your
              two-wheeler insurance policy
            </h3>
            <ol className="productpage_OLpoints">
              <li>
                <span>Zero Depreciation Cover -</span> Ensures full coverage for
                repair/replacement costs without deducting depreciation.
              </li>
              <li>
                <span>Personal Accident Cover -</span> Provides financial
                compensation for accidental death or disability of the insured
                person.
              </li>
              <li>
                <span>Return to Invoice Cover -</span> Guarantees reimbursement
                of the Two-Wheeler's original invoice price in case of total
                loss or theft.
              </li>
              <li>
                <span>Engine and Gearbox Protection -</span> Covers
                repair/replacement costs for engine and gearbox due to specified
                damages.
              </li>
              <li>
                <span>Consumable Cover -</span> Reimburses expenses on
                consumable items used during Two-Wheeler repair.
              </li>
              <li>
                <span>Breakdown Assistance -</span> Offers roadside assistance
                for mechanical breakdowns, including towing and minor repairs.
              </li>
              <li>
                <span>EV Shield Add-on Cover -</span> Tailored protection for
                electric Two-Wheelers, covering unique risks like battery and
                electrical components.
              </li>
              <li>
                <span>Roadside Assistance Cover -</span> Covers costs related to
                Two-Wheeler breakdown.
              </li>
            </ol>
            <p className="Note">
              *Add on may vary subject to the insurance provider.
            </p>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12} marginBottom={"16px"}>
            <h3 className="mb-2">
              Comparison of Two-Wheeler Insurance Policies
            </h3>
            <p>
              Below is a comparison table illustrating the differences between
              Standalone Own-damage, Comprehensive, and Third-party Two-Wheeler
              Insurance:
            </p>
          </Grid>
          <Grid xs={12}>
            <Box className="comp_tabelWrapper">
              <table
                className="comparison_table"
                cellSpacing={0}
                cellPadding={0}
              >
                {/* table heading */}
                <tr>
                  <th>Coverage & Features</th>
                  <th>Third-party</th>
                  <th>Standalone Own-damage</th>
                  <th>Comprehensive</th>
                </tr>

                <tr>
                  <td>Damage/loss to your Two-Wheeler</td>
                  <td>No</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Theft of your two-wheeler</td>
                  <td>No</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Damages to third-party vehicle/property</td>
                  <td>Yes</td>
                  <td>No</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Third-party injury/death</td>
                  <td>Yes</td>
                  <td>No</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>NCB discount on OD premium</td>
                  <td>No</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Cashless claims</td>
                  <td>No</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Online transactions</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Personal accident cover</td>
                  <td>Yes</td>
                  <td>No</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Fire damage</td>
                  <td>No</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Natural calamities coverage</td>
                  <td>No</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Customizable add-ons</td>
                  <td>No</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Roadside assistance</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>Engine protection</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>Consumable’s cover</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>Key replacement</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>Emergency medical expenses</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>Third-party property damage limit</td>
                  <td>Yes</td>
                  <td>No</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Repair at network garages</td>
                  <td>No</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Towing assistance</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>Accidental hospitalization expenses</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>Zero Depreciation Cover</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>Return to Invoice Cover</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>Engine and Gearbox Protection</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>Consumable Cover</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>Breakdown Assistance</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>Tyre Protection</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>Daily Conveyance Benefit</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>EV Shield Add on cover</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>Emergency Assistance Cover</td>
                  <td>No</td>
                  <td>Optional</td>
                  <td>Optional</td>
                </tr>
              </table>
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-2">Why Choose Us?</h3>
            <ol className="productpage_OLpoints">
              <li>
                <span>Tailored Solutions -</span> Customized plans to meet your
                specific needs.
              </li>
              <li>
                <span>Affordable Premiums -</span> Competitive pricing with
                flexible payment options.
              </li>
              <li>
                <span>Digital Convenience -</span> Easy online policy purchase
                and renewal process.
              </li>
              <li>
                <span>Reliable Partners -</span> Collaborations with top to
                bring you the best plans.
              </li>
              <li>
                <span>Customer-Centric Approach -</span> Dedicated support and
                easy claim settlement process.
              </li>
            </ol>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-4">Our Partners</h3>
            <ul className="insurer-partnersList">
              <li>
                <img
                  src="./images/insurers/Go-Digit-General-Insurance.svg"
                  alt=""
                />
              </li>
              <li>
                <img
                  src="./images/insurers/HDFC-ERGO-General-Insurance-Co-Ltd.svg"
                  alt=""
                />
              </li>
              <li>
                <img
                  src="./images/insurers/ACKO_general_insurance.svg"
                  alt=""
                />
              </li>
            </ul>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row" marginBottom={"30px"}>
          <Grid xs={12}>
            <h3 className="mb-4">Two-wheeler Insurance FAQ’s</h3>
            <FAQ data={showAllFaqs ? faqs : faqs.slice(0, defaultShow)} />
          </Grid>
          <Grid xs={12} textAlign={"right"}>
            <CustomButton
              className="primaryBtn large "
              fullWidth={false}
              variant="contained"
              text={showAllFaqs ? "Read Less..." : " Read More..."}
              onClick={() => setShowAllFaqs(!showAllFaqs)}
            />
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default TWProductPage;
