import { BrowserRouter, useLocation } from "react-router-dom";
import CommonRoutes from "./Routing/Routes/CommonRoutes";
import MyAccount from "./Page/Desktop/MyAccount/MyAccount";
import MMyAccount from "./Page/Mobile/MMyAccount/MMyAccount";
import { useEffect } from "react";

function App() {
  
  return (
    <BrowserRouter>
      <CommonRoutes />
    </BrowserRouter>
  );
}

export default App;
