import React from "react";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./Footer.scss";
import { COLORS } from "../../../../SupportingFiles/colors";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";

function Footer() {
  const navigate = useNavigate();
  return (
    <Box className="footer">
      <Box className="top-sec">
        <Grid container className="row" columnSpacing={3} marginBottom={"24px"}>
          <Grid xs={3}>
            <Box marginBottom={"50px"}>
              <img
                src="./images/motilalOswal-logo.svg"
                height="50px"
                alt="Motilal Oswal Insurance"
                onClick={() => navigate(COMMON_ROUTES.HOME)}
                style={{ cursor: "pointer" }}
              />
            </Box>
            <Box className="irdai-box">
              <img src="./images/irda-logo.svg" alt="irda" height={"60px"} />
              <Box>
                <p>
                  <span>Corporate Agent (Composite)</span> <br />
                  Registration No: <span>CA0579 </span> <br />
                  For the period <span>11-Jul-2024</span> to{" "}
                  <span>10-Jul-2027</span>{" "}
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid xs={2}>
            <h5 className="mb-4">Company</h5>
            <ul>
              <li>
                <Link href={COMMON_ROUTES.HOME}>Home</Link>
              </li>
              <li>
                <Link href={COMMON_ROUTES.ABOUT_US}>About Us</Link>
              </li>
              <li>
                <Link href={COMMON_ROUTES.CONTACT_US}>Contact</Link>
              </li>
              <li>
                <Link href={COMMON_ROUTES.CLAIMS}>Claims</Link>
              </li>
              <li>
                <Link href={COMMON_ROUTES.COMPLAINT}>Complaint</Link>
              </li>
            </ul>
          </Grid>

          <Grid xs={2}>
            <h5 className="mb-4">Others</h5>
            <ul>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.PRIVACY_POLICY)}>
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.DISCLAIMER)}>
                  Disclaimer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    navigate(COMMON_ROUTES.REFUND_AND_CANCELLATION)
                  }
                >
                  Cancellation & Refund
                </Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.FAQ)}>FAQ</Link>
              </li>
              <li>
                <Link onClick={() => navigate(COMMON_ROUTES.TERM_AND_CONDTION)}>
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </Grid>

          <Grid xs={2}>
            <h5 className="mb-4">Products</h5>
            <ul>
              <li>
                <Link href={COMMON_ROUTES.PRODUCT_HEALTH}>
                  Health insurance
                </Link>
              </li>
              <li>
                <Link href={COMMON_ROUTES.PRODUCT_TERM}>Term insurance</Link>
              </li>
              <li>
                <Link href={COMMON_ROUTES.PRODUCT_CAR}>Car insurance</Link>
              </li>
              <li>
                <Link href={COMMON_ROUTES.PRODUCT_TW}>
                  Two-Wheeler insurance
                </Link>
              </li>
            </ul>
          </Grid>

          <Grid xs={3}>
            <h5 className="mb-4">Social Media</h5>
            <Box className="social-icons">
              <a
                href="https://www.facebook.com/MotilalOswalSecurities"
                target="_blank"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.42322 12.055V22.1574C9.42322 22.3221 9.53303 22.4319 9.69774 22.4319H13.4312C13.5959 22.4319 13.7058 22.3221 13.7058 22.1574V11.8902H16.3961C16.5059 11.8902 16.6706 11.7804 16.6706 11.6706L16.9451 8.59599C16.9451 8.43128 16.8353 8.32147 16.6706 8.32147H13.7058V6.12529C13.7058 5.63116 14.145 5.19192 14.6391 5.19192H16.7255C16.8902 5.19192 17 5.08211 17 4.9174V1.84276C17 1.67805 16.8902 1.56824 16.7255 1.56824H13.2116C11.1253 1.56824 9.47812 3.27027 9.47812 5.30173V8.26656H7.61137C7.44666 8.26656 7.33685 8.37637 7.33685 8.54109V11.6157C7.33685 11.7804 7.44666 11.8902 7.61137 11.8902H9.47812V12.055H9.42322Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a href="https://twitter.com/MotilalOswalltd" target="_blank">
                <svg
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1193_1715)">
                    <path
                      d="M17.6073 20.1818H19.6364L6.48 2.16H4.29818L17.6073 20.1818ZM18.8945 0H22.5818L14.5309 9.51273L24 22.4727H16.6036L10.8 14.6182L4.14545 22.4727H0.48L9.07636 12.3055L0 0H7.59273L12.8291 7.17818L18.8945 0Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1193_1715">
                      <rect width="24" height="22.4727" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a href="https://www.linkedin.com/" target="_blank">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.80438 8.6801H8.02228V18.9595H4.80438V8.6801ZM6.41333 3.54041C7.44127 3.54041 8.24574 4.38957 8.24574 5.37282C8.24574 6.40076 7.39657 7.24992 6.41333 7.24992C5.38539 7.24992 4.53622 6.40076 4.53622 5.37282C4.53622 4.38957 5.38539 3.54041 6.41333 3.54041ZM9.98877 18.9595H13.2067V13.8645C13.2067 12.5237 13.4748 11.2276 15.1285 11.2276C16.7821 11.2276 16.7821 12.7472 16.7821 13.9539V18.9595H20V13.3282C20 10.5572 19.419 8.41194 16.1564 8.41194C14.5921 8.41194 13.5642 9.26111 13.1173 10.0656H13.0726V8.6801H9.98877V18.9595Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/motilaloswalgroup/"
                target="_blank"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7225 7.88372C17.7225 8.40155 17.291 8.83307 16.7731 8.83307C16.2553 8.83307 15.8238 8.40155 15.8238 7.88372C15.8238 7.36589 16.2553 6.93436 16.7731 6.93436C17.291 6.93436 17.7225 7.36589 17.7225 7.88372ZM16.5142 12.2421C16.5142 14.486 14.7018 16.2984 12.4579 16.2984C10.214 16.2984 8.40155 14.486 8.40155 12.2421C8.40155 9.99819 10.214 8.18579 12.4579 8.18579C14.7018 8.14263 16.5142 9.99819 16.5142 12.2421ZM15.047 12.2421C15.047 10.8181 13.8819 9.65297 12.4579 9.65297C11.0338 9.65297 9.86873 10.8181 9.86873 12.2421C9.86873 13.6661 11.0338 14.8313 12.4579 14.8313C13.8819 14.8313 15.047 13.6661 15.047 12.2421ZM20.7 8.96253C20.7 6.20077 18.4992 4 15.7375 4H8.96253C6.20077 4 4 6.20077 4 8.96253V15.7375C4 18.4992 6.20077 20.7 8.96253 20.7H15.7375C18.4992 20.7 20.7 18.4992 20.7 15.7375V8.96253ZM19.0602 15.6512C19.0602 17.5499 17.5499 19.0602 15.6512 19.0602H9.04883C7.15012 19.0602 5.63979 17.5499 5.63979 15.6512V9.04883C5.63979 7.15013 7.15012 5.63979 9.04883 5.63979H15.6512C17.5499 5.63979 19.0602 7.15013 19.0602 9.04883V15.6512Z"
                    fill="white"
                  />
                </svg>
              </a>
            </Box>
          </Grid>
        </Grid>
        <Box className="lightOrange-box" marginBottom={"24px"}>
          <Grid container className="row" columnSpacing={3}>
            <Grid xs={12}>
              <p className="mb-4">
                DISCLAIMER: All the images used on motilal.in have either been
                purchased or are used with permission of the copyright holder.
                Images from www.motilaloswal.com. may not be copied or used
                elsewhere without obtaining appropriate permissions from the
                copyright holders. All Rights reserved.
              </p>
              <p>
                Insurance is the subject matter of Solicitation.
                www.motilaloswal.com., India's IRDA approved web aggregator
                portal. We at Motilal provide an unbiased and comprehensive
                online insurance comparison platform to our clients.
                www.motilaloswal.com. provides FREE quotes and insurance plans
                from the best companies at cost-effective rates.
              </p>
            </Grid>
          </Grid>
        </Box>
        <Grid container className="row" columnSpacing={3}>
          <Grid xs={12} textAlign={"center"}>
            <p style={{ fontSize: "14px", textAlign: "center" }}>
              Motilal Oswal Insurance Broking Services Ltd., Office: 48, Jaora
              Compound, MYH Road, Indore India.
            </p>
            <p style={{ fontSize: "14px", textAlign: "center" }}>
              Copyright 2024 insurance.motilaloswal.com. All Rights Reserved.
            </p>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
export default Footer;
